/*eslint-disable*/
import React, { useState, useEffect } from "react";
import "./FundHeader.scss";
import Background from "../../../assets/bg-poolfund-top-banner.png";
import { PaypalButton, CardPayButton } from "../../Reusable/Button/Button";
import SimpleReactValidator from "simple-react-validator";

import PaypalPay from "../../Reusable/Modal/PaypalPay/PaypalPay";
import QuickPay from "../../Reusable/Modal/QuickPay/QuickPay";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import MiddlePopup from "../../Reusable/Modal/MiddlePopup/MiddlePopup";

function FundHeader(props) {
  const { auth } = props;
  const { location } = useLocation();
  const [validator, setValidator] = useState(
    new SimpleReactValidator({
      autoForceUpdate: this,
      messages: { email: "That is not an email." },
    })
  );

  const [amount, setAmount] = useState("");
  const [recurring, setRecurring] = useState(false);
  const [tempConfirmAlert, setTempConfirmAlert] = useState(null);
  const [showQuickPay, setShowQuickPay] = useState(false);
  const [showPaypal, setShowPaypal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [modalData, setModalData] = useState(null);

  const payment = useSelector((state) => state.payment);

  useEffect(() => {
    if (location?.search && auth.token) {
      var url = new URL(window.location.href);
      var PayerID = url.searchParams.get("PayerID");
      var PlanToken = url.searchParams.get("token");
      var PaymentId = url.searchParams.get("paymentId");
      window.history.replaceState(null, null, location.pathname);
      const data = {
        PayerID: PayerID,
        PaymentId: PaymentId,
        beneficiaryId: 1,
        type: "elf",
        recurring: false,
        month: 1,
      };
      //If not recurring payment
      if (PayerID) {
        props.getPaypalExecute(data, auth.token);
      }
      //If recurring payment
      if (!PaymentId && PlanToken) {
        data.PayerID = undefined;
        data.PaymentId = undefined;
        data.token = PlanToken;
        data.recurring = true;

        props.getPaypalExecute(data, auth.token);
      }
    }
  }, [auth.token && location?.search]);
  useEffect(() => {
    if (payment.data || payment.error) {
      setShowPaypal(false);
      if (payment.data) {
        setShowPopup(true);
        setModalData(payment.data);
      }
    }
  }, [payment.data || payment.error]);
  useEffect(() => {
    if (auth.token && tempConfirmAlert) {
      setTempConfirmAlert(false);
      if (tempConfirmAlert === "quickpay") {
        handleQuickPay();
      }
      if (tempConfirmAlert === "paypal") {
        handlePaypal();
      }
    }
  }, [auth.token, tempConfirmAlert]);

  useEffect(() => {
    if (payment.data) {
      setShowPaypal(false);
      setShowQuickPay(false);
      setAmount("");
    }
  }, [payment]);

  const onchangeHandler = (e) => {
    const inputValue = e.target.value;

    // Remove commas from the input value
    const cleanedValue = inputValue.replace(/,/g, "");

    // Check if the cleaned input is a valid number or empty string
    if (!isNaN(cleanedValue) || cleanedValue === "" || cleanedValue === ".") {
      setAmount(cleanedValue);

      // Show validation message if needed
      if (!validator.allValid()) {
        validator.showMessageFor(e.target.name);
      }
    }
  };

  const recurringHandler = () => {
    setRecurring(!recurring);
  };

  const handlePaypalPayment = (user) => {
    const data = {
      amount: amount,
      type: "elf",
      beneficiaryId: 1,
      recurring: recurring, // have an issue in recurring payment of paypal
      //recurring: false,
      month: 1,
    };
    if (!auth.token) {
      data.user = user;
    }
    props.createPaypalBilling(data, auth.token);
  };

  const pageSection = props.page?.pageSections?.find(
    (s) => s.section.key === "educate-lanka-fund-header"
  );

  return (
    <div
      className="FundHeader"
      style={{ backgroundImage: `url(${pageSection?.data?.image})` }}
    >
      <div className="FundBox">
        <div
          dangerouslySetInnerHTML={{ __html: pageSection?.data.text1 }}
        ></div>
        <div className="inputContainer">
          <div className="TwoTag">
            {validator.message("amount", amount, "currency")}
            <input
              type="text"
              name="amount"
              value={amount}
              onChange={onchangeHandler}
            />
            <span>$</span>
            <span>USD</span>
          </div>

          <CardPayButton
            onClick={() => setShowQuickPay(true)}
            disabled={validator.allValid() && amount > 0 ? false : true}
            title="Donate by"
          />
          <PaypalButton
            disabled={validator.allValid() && amount > 0 ? false : true}
            title="Donate by"
            onClick={() => setShowPaypal(true)}
          />
        </div>

        {amount && validator.allValid() ? (
          <>
            {showQuickPay && (
              <QuickPay
                poolfund={true}
                onHide={() => setShowQuickPay(false)}
                recurring={recurring}
                recurringHandler={recurringHandler}
                amount={amount}
                elf={true}
                show={showQuickPay}
              />
            )}
            {showPaypal && (
              <PaypalPay
                onHide={() => setShowPaypal(false)}
                recurring={recurring}
                recurringHandler={recurringHandler}
                amount={amount}
                elf={true}
                show={showPaypal}
                handlePaypalPayment={handlePaypalPayment}
              />
            )}
          </>
        ) : null}
        <MiddlePopup
          show={showPopup}
          onHide={() => setShowPopup(false)}
          modalData={modalData}
          donationType={"elf"}
        />
        <div
          dangerouslySetInnerHTML={{ __html: pageSection?.data.text2 }}
        ></div>
      </div>
    </div>
  );
}

export default FundHeader;
