import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BlueImpactArea from "../../components/Reusable/BlueImpactArea/BlueImpactArea";
import { useParams } from "react-router";
import SponsorshipView from "../../components/Sponsorship/View/View";
import "./Sponsorship.scss";
import * as actionCreators from "../../store/actions";
import Loading from "../../components/Reusable/Loading/Loading";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "../../store/actions/actionsTypes";
import axios from "../../axios";

const successPayment = (data) => ({
  type: actions.SUCCESS_PAYMENT,
  data,
});

function Sponsorship(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sponsorship, loading } = useSelector((state) => state.sponsorships);
  const payment = useSelector((state) => state.payment);
  const auth = useSelector((state) => state.auth);
  const param = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isDisplayMessage, setIsDisplayMessage] = useState(false);

  const student = sponsorship?.beneficiary;
  useEffect(() => {
    if (auth.token && param.id) {
      dispatch(actionCreators.getSponsorship(param.id, auth.token));
    }
  }, [param.id, auth.token, dispatch, payment.data]);

  useEffect(() => {
    if (!isDisplayMessage && auth.token) {
      const paymentSuccessMessage = searchParams.get("payment_success");
      const paymentErrorMessage = searchParams.get("payment_error");
      if (paymentSuccessMessage) {
        getSponsorship(param.id, auth.token);
      } else if (paymentErrorMessage) {
        toast.warning(`${paymentErrorMessage}`);
      }
      setIsDisplayMessage(true);
      navigate(`/sponsorship/${param.id}`);
    }
  }, [isDisplayMessage]);

  const getSponsorship = (id, token) => {
    axios
      .get(`sponsorships/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        dispatch(
          successPayment({
            message: "Payment created successfully",
            data: res.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let content = null;
  let loadingSpin = null;

  if (loading || payment.loading) {
    loadingSpin = <Loading page />;
  }

  if (sponsorship) {
    content = (
      <div className="Sponsorship">
        <div className="wrapper">
          <h1 className="Heading">
            I'm sponsoring <strong>{student.firstName}'s</strong> education
            starting from{" "}
            <span>
              <strong>${sponsorship?.amount} per month.</strong>
            </span>
          </h1>
        </div>
        <hr />
        <SponsorshipView
          sponsorship={sponsorship}
          student={student}
          auth={auth}
          payment={payment}
          payWithStripe={props.payWithStripe}
          createPaypalBilling={props.createPaypalBilling}
          getPaypalExecute={props.getPaypalExecute}
        />

        <BlueImpactArea />
      </div>
    );
  }

  return (
    <>
      {loadingSpin}
      {content}
    </>
  );
}

const mapStateToProps = (state) => ({
  student: state.students,
  auth: state.auth,
  giftcard: state.giftcard,
});

const mapDispatchToProps = (dispatch) => ({
  createPaypalBilling: (data, token) =>
    dispatch(actionCreators.createPaypalBilling(data, token)),
  getPaypalExecute: (data, token) =>
    dispatch(actionCreators.getPaypalExecute(data, token)),
  resetPayment: () => dispatch(actionCreators.resetPayment()),
  payWithStripe: (data, token) =>
    dispatch(actionCreators.payWithStripe(data, token)),
  getGiftcard: (token, id) => dispatch(actionCreators.getGiftcard(token, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sponsorship);
