/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { ButtonBlueLine, CardPayButton, PaypalButton } from "../../../Reusable/Button/Button";
import "./MultiStudenModal.scss";
import { useSelector } from "react-redux";
import Loading from "../../../Reusable/Loading/Loading";
import Select from "react-select";
import moment from "moment";

const dollerList = [
  { label: "5%", value: 5, name: "dollerList" },
  { label: "10%", value: 10, name: "dollerList" },
  { label: "15%", value: 15, name: "dollerList" },
  { label: "20%", value: 20, name: "dollerList" },
  { label: "25%", value: 25, name: "dollerList" },
  { label: "Custom", value: "custom", name: "dollerList" },
];

const MultiStudenModal = (props) => {
  const { data: sponsor } = useSelector((state) => state.sponsor);
  const data = sponsor?.sponsorships.map((item) => ({
    studentName: `${item.beneficiary?.firstName} ${item.beneficiary?.lastName || ""}`.trim(),
    beneficiaryId: item.beneficiaryId,
    status: item.status,
    image: item.beneficiary?.image,
    lastPayment: item.payments.length === 0 ? "-" : item.payments[item.payments.length - 1].createdAt,
    nextPayment: item.payments.length === 0 ? "-" : item.payments[item.payments.length - 1].dueDate,
    type: item.recurring ? "recurring" : "not-recurring",
    paymentValue: `${item.amount}$`,
  }));

  const { meLoading, loading } = useSelector((state) => state.auth);
  const isLoading = meLoading || loading;

  const [selectedRows, setSelectedRows] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [selectedTip, setSelectedTip] = useState(10);
  const [totalPaymentWithTip, setTotalPaymentWithTip] = useState("$0.00");
  const [currentStep, setCurrentStep] = useState(1);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [customTip, setCustomTip] = useState(null);

  const activeStudents = data?.filter((student) => student.status === "pending" || student.status === "active") || [];

  const handleCheckboxChange = (studentName) => {
    const updatedSelectedRows = selectedRows.includes(studentName)
      ? selectedRows.filter((name) => name !== studentName)
      : [...selectedRows, studentName];

    setSelectedRows(updatedSelectedRows);
    const selectableStudents = activeStudents
      .filter((student) => student.type !== "recurring")
      .map((student) => student.studentName);

    setIsAllSelected(
      selectableStudents.length > 0 &&
      updatedSelectedRows.length === selectableStudents.length
    );
  };

  const handleHeaderCheckboxChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const selectableStudents = activeStudents
        .filter((student) => student.type !== "recurring")
        .map((student) => student.studentName);
      setSelectedRows(selectableStudents);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleTipChange = (selectedOption) => {
    if (selectedOption.value === "custom") {
      setSelectedTip("custom");
      setCustomTip(0);
    } else {
      setSelectedTip(selectedOption.value);
    }
  };

  const handleNextClick = () => {
    const selectedData = data.filter((student) =>
      selectedRows.includes(student.studentName)
    );
    const rowsWithDefaults = selectedData.map((student) => ({
      ...student,
      paymentPeriod: "1m",
      totalPayment: student.paymentValue,
    }));
    setUpdatedRows(rowsWithDefaults);
    setCurrentStep(2);
  };

  const handleBackClick = () => {
    setCurrentStep(1);
  };

  const handlePeriodChange = (studentName, period) => {
    setUpdatedRows((prevRows) =>
      prevRows.map((row) => {
        if (row.studentName === studentName) {
          const numericValue = parseFloat(row.paymentValue.replace("$", ""));
          let multiplier = 1;
          if (period === "3m") multiplier = 3;
          else if (period === "6m") multiplier = 6;
          else if (period === "1y") multiplier = 12;

          return {
            ...row,
            paymentPeriod: period,
            totalPayment: `$${(numericValue * multiplier).toFixed(2)}`,
          };
        }
        return row;
      })
    );
  };

  const calculateTotalPaymentWithTip = () => {
    const total = updatedRows.reduce((acc, row) => {
      const numericValue = parseFloat(row.totalPayment.replace("$", ""));
      return acc + numericValue;
    }, 0);

    const tipPercentage = selectedTip === "custom" ? (customTip ? customTip : 0) : selectedTip;
    const totalWithTip = total + (total * tipPercentage) / 100;
    setTotalPaymentWithTip(`$${totalWithTip.toFixed(2)}`);
  };

  useEffect(() => {
    if (selectedTip === "custom") {
      calculateTotalPaymentWithTip()
    }
  }, [selectedTip, customTip])

  useEffect(() => {
    calculateTotalPaymentWithTip();
  }, [selectedTip, updatedRows]);

  useEffect(() => {
    if (!props.show) {
      setSelectedRows([]);
      setUpdatedRows([]);
      setCurrentStep(1);
      setIsAllSelected(false);
      setSelectedTip(10);
      setCustomTip(null);
    }
  }, [props.show]);

  const WriteConsole = () => {
    console.log({ ...updatedRows, selectedTip, customTip, totalPaymentWithTip })
  }

  return (
    <Modal className="MultiStudenModal" {...props}>
      {isLoading && <Loading />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Sponsorship(s) Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep === 1 && (
          <>
            <div className="ScrollTable">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "70px" }}>
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleHeaderCheckboxChange}
                      />
                      {" "}All
                    </th>
                    <th>Student</th>
                    <th>Status</th>
                    <th>Last Payment</th>
                    <th>Payment Due</th>
                  </tr>
                </thead>
                <tbody>
                  {activeStudents.map((student, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          disabled={student.type === "recurring"}
                          checked={selectedRows.includes(student.studentName)}
                          onChange={() => handleCheckboxChange(student.studentName)}
                        />
                      </td>
                      <td>
                        <img
                          src={student.image}
                          alt={student.studentName}
                          style={{ width: "40px", borderRadius: "50%" }}
                        />
                        {'  '}
                        {student.studentName}
                      </td>
                      <td style={{ color: student.status === "active" ? "green" : "red" }}>
                        {student.status.charAt(0).toUpperCase() + student.status.slice(1)}
                      </td>

                      <td>
                        {student.lastPayment === "-"
                          ? "-"
                          : moment.utc(student.lastPayment).local().format('YYYY-MM-DD')}
                      </td>

                      <td
                        style={{
                          color:
                            new Date(student.nextPayment) < new Date() ? "red" : "inherit",
                        }}
                      >
                        {student.type === "recurring" ? (
                          <span className="btn btn-success btn-rounded">Recurring</span>
                        ) : (
                          student.nextPayment === "-" ? "-" : moment.utc(student.nextPayment).local().format('YYYY-MM-DD')
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end pr-1 mt-4">
              <ButtonBlueLine
                title="Next"
                disabled={selectedRows.length === 0}
                onClick={handleNextClick}
              />
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <div className="d-flex justify-content-start">
              <ButtonBlueLine title="Back" onClick={handleBackClick} />
            </div>
            <div>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Monthly Amount</th>
                    <th>Payment Period</th>
                    <th className="d-flex justify-content-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {updatedRows.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={row.image}
                          alt={row.studentName}
                          style={{ width: "40px", borderRadius: "50%" }}
                        />
                        {'  '}
                        {row.studentName}
                      </td>
                      <td>{row.paymentValue}</td>
                      <td>
                        <Select
                          value={{
                            label: row.paymentPeriod === "1m"
                              ? "1 Month"
                              : `${row.paymentPeriod.replace("m", " Months").replace("y", " Year")}`,
                            value: row.paymentPeriod,
                          }}
                          onChange={(selectedOption) =>
                            handlePeriodChange(row.studentName, selectedOption.value)
                          }
                          options={[
                            { value: "1m", label: "1 Month" },
                            { value: "3m", label: "3 Months" },
                            { value: "6m", label: "6 Months" },
                            { value: "1y", label: "1 Year" },
                          ]}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              width: "150px",
                            }),
                          }}
                        />
                      </td>
                      <td className="d-flex justify-content-end">
                        {row.totalPayment || row.paymentValue}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4">
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>Tip:</td>
                    <td className="d-flex justify-content-end">
                      <span className="optianalSpan">
                        {selectedTip === "custom" ? (
                          <div className="tip-input-wraper">
                            <input
                              type="number"
                              value={customTip}
                              onChange={(e) => setCustomTip(parseFloat(e.target.value))}
                              style={{ width: "100px" }}
                            />
                          </div>

                        ) : (
                          <Select
                            value={dollerList.find((option) => option.value === selectedTip)}
                            onChange={handleTipChange}
                            options={dollerList}
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                width: "100px",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "100px",
                              }),
                            }}
                          />
                        )}
                      </span>

                    </td>

                  </tr>
                  <tr>
                    <td>Total with Tip:</td>
                    <td className="d-flex justify-content-end">{totalPaymentWithTip}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <ButtonBlueLine title="console" className="mt-4" onClick={WriteConsole} /> */}
            <hr />
            <Row className="justify-content-center mb-3 mt-3">
              <Col md={6}>
                <CardPayButton title={"Pay with"} subTitle={"Pay with Credit Card"} lg />
              </Col>
              <Col md={6}>
                <PaypalButton title={"Pay with"} subTitle={"Pay with PayPal"} lg />
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MultiStudenModal;
