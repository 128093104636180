/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import FundHeader from "../../components/PoolFund/FundHeader/FundHeader";
import "./PoolFund.scss";
import {
  createPaypalBilling,
  payWithStripe,
  getPaypalExecute,
  resetPayment,
} from "../../store/actions";
import Loading from "../../components/Reusable/Loading/Loading";
import PageSections from "../../components/Reusable/PageSections/PageSections";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as actions from "../../store/actions/actionsTypes";

const successPayment = (data) => ({
  type: actions.SUCCESS_PAYMENT,
  data,
});

function Fund(props) {
  const { list, loading } = useSelector((state) => state.pages);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [isDisplayMessage, setIsDisplayMessage] = useState(false);

  useEffect(() => {
    return () => props.resetPayment();
  }, []);

  useEffect(() => {
    if (!isDisplayMessage && props.auth.token) {
      const paymentSuccessMessage = searchParams.get("payment_success");
      const paymentErrorMessage = searchParams.get("payment_error");
      const amount = searchParams.get("amount");
      if (paymentSuccessMessage) {
        dispatch(
          successPayment({
            message: "Payment created successfully",
            data: {
              tip: "0.00",
              amount: amount,
              type: "elf",
            },
          })
        );
      } else if (paymentErrorMessage) {
        toast.warning(`${paymentErrorMessage}`);
      }
      setIsDisplayMessage(true);
      navigate("/educate-lanka-fund");
    }
  }, [isDisplayMessage]);

  let loadingContent;
  if (props.payment.loading || props.auth.meLoading) {
    loadingContent = <Loading />;
  }

  const page = list?.find((p) => p.group === "/educate-lanka-fund");
  return (
    <div>
      {loadingContent}
      {page && (
        <>
          <FundHeader page={page} {...props} />
          <PageSections pageSections={page.pageSections} />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
});
const mapDispatchToProps = (dispatch) => ({
  createPaypalBilling: (data, token) =>
    dispatch(createPaypalBilling(data, token)),
  payWithStripe: (data, token) => dispatch(payWithStripe(data, token)),
  getPaypalExecute: (data, token) => dispatch(getPaypalExecute(data, token)),
  resetPayment: () => dispatch(resetPayment()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Fund);
