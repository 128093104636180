import React from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button } from "../../Button/Button";
import addThousandSeparator from "../../../../utils/addThousandSeparator";

import "./PaypalPay.scss";

const PaypalPay = ({ ...props }) => {
  const { token } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm();

  const handleFormSubmit = (e) => {
    if (props.elf) {
      props.handlePaypalPayment(getValues());
    } else {
      props.handlePaypalPayment();
    }
  };

  let button = (
    <Button
      title={`Donate $${addThousandSeparator(props.amount)} ${
        props?.recurring ? "every" : ""
      } ${props.month} month`}
      full
      lg
    />
  );

  if (props.sponsorship) {
    button = (
      <Button
        title={`Pay $${addThousandSeparator(props.amount)} ${
          props?.recurring ? "every" : "for"
        } ${props.month === 12 ? 1 : props.month}
    ${props.month === 12 ? "year" : props.month === 1 ? "month" : "months"} 
    via PayPal`}
        full
        lg
      />
    );
  }

  if (props.elf) {
    button = (
      <>
        {!token && (
          <Row>
            <Col md={6} className="inputContainer">
              {errors.firstName && errors.firstName.message && (
                <p className="errorMessage">{errors.firstName.message}</p>
              )}

              <input
                placeholder="First name"
                type="text"
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "First name required.",
                  },
                })}
              />
            </Col>

            <Col md={6} className="inputContainer">
              {errors.lastName && errors.lastName.message && (
                <p className="errorMessage">{errors.lastName.message}</p>
              )}
              <input
                placeholder="Last name"
                type="text"
                {...register("lastName", {
                  required: {
                    value: true,
                    message: "Last name",
                  },
                })}
              />
            </Col>
            <Col md={12} className="inputContainer">
              {errors.email && errors.email.message && (
                <p className="errorMessage">{errors.email.message}</p>
              )}
              <input
                placeholder="Email"
                type="text"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Email required.",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Enter valid email format",
                  },
                })}
              />
            </Col>
          </Row>
        )}
        <label
          onClick={props.recurringHandler}
          className={`checkLabel ${props?.recurring ? "recurring" : null}`}
        >
          Donate monthly
        </label>

        <Button
          disabled={!token && !isValid}
          title={`Donate $${addThousandSeparator(props?.amount)}  ${props?.recurring ? "monthly" : ""} `}
          full
          lg
        />

        {/* <Button
          disabled={!token && !isValid}
          title={`Donate $${addThousandSeparator(props?.amount)}`}
          full
          lg
        /> */}
      </>
    );
  }

  return (
    <Modal {...props} className="PaypalPay">
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>
          <div className="TitleContent">
            {props.elf ? (
              <h2>Educate Lanka Fund</h2>
            ) : (
              <>
                <h5>Educate Lanka</h5>
                <h2>Student Sponsorship Payment</h2>
              </>
            )}
            <small>
              Investing in their future, one deserving student at a time.
            </small>
            <img src={LogoIco} width="50" alt="logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-4">
        {props.sponsorship ? (
          <p className="text-center mb-5">
            Please proceed and you will be directed to the PayPal site to
            complete the payment.
          </p>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit(handleFormSubmit)}>{button}</form>
        <br />
        <br />
      </Modal.Body>
    </Modal>
  );
};

export default PaypalPay;
