import React, { useState, lazy, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./TopProfileDetail.scss";
import Image from "../../../assets/user.png";
import { ButtonBlueLine } from "../../Reusable/Button/Button";

import Loading from "../../Reusable/Loading/Loading";
import { isValidHttpUrl } from "../../../utils/func";
import moment from "moment";
import MultiStudenModal from "./MultiStudenModal/MultiStudenModal";

const EditProfileModal = lazy(() =>
  import("./EditProfileModal/EditProfileModal")
);

const TopProfileDetail = (props) => {
  const [editModal, setEditModal] = useState(false);
  const [multiStudentModal, setMultiStudentModal] = useState(false);
  const navigate = useNavigate();
  // const [studentsModal, setStudentsModal] = useState(false);
  // const [quickPay, setQuickPay] = useState(false);

  const handleEditProfileModal = (e) => {
    setEditModal(!editModal);
  };
  const handleMultiStudentModal = (e) => {
    setMultiStudentModal(!multiStudentModal);
  }
  // const handleStudentSummeryModal = (e) => {
  //   setStudentsModal(!studentsModal);
  // };
  // const handleQuickPayModal = () => {
  //   setQuickPay(!quickPay);
  //   setStudentsModal(false);
  // };

  const me = props.auth.me.data;
  const sponsor = props.sponsor.data;
  const sponsored = sponsor?.sponsorships?.length + sponsor?.legacySponsorships?.length;

  let sponseButton = null;
  if (props.sponsor.loading) {
    sponseButton = <Loading button />;
  }

  if (sponsor) {
    // if (sponsor.sponsorships.length > 0) {
    //   const days = moment().diff(sponsor.createdAt, "days");
    //   // const year = parseInt(days / 365);
    //   // const month = parseInt((days % 365) / 30);
    //   sponseButton = (
    //     <>
    //       <h4 className="darkLight">
    //         You have sponsored for
    //         <strong> {sponsor.sponsorships.length} students</strong> for the
    //         past{" "}
    //         <strong>
    //           {/* {year > 0 && year + " year and "}
    //           {month > 0 && month + " Month "} */}
    //           {days > 0 && days + " days"}
    //         </strong>{" "}
    //         <span className="colorBlue">Thank you!</span>
    //       </h4>
    //       {sponsor.sponsorships.length > 0 && (
    //         <>
    //           <ButtonBlueLine
    //             title="VIEW ALL STUDENTS SUMMARY"
    //             onClick={handleStudentSummeryModal}
    //           />
    //           <StudentsSummary
    //             sponsorships={sponsor.sponsorships.filter(
    //               (sp) => sp.type === "student"
    //             )}
    //             quickPayModal={handleQuickPayModal}
    //             studentsModal={studentsModal}
    //             studentSummeryModal={handleStudentSummeryModal}
    //           />
    //         </>
    //       )}
    //     </>
    //   );
    // } else {
    //   sponseButton = (
    //     <h4 className="darkLight">
    //       You have not yet contributed to the sponsorship
    //     </h4>
    //   );
    // }
  }

  let image = null;
  if (me?.image) {
    image = isValidHttpUrl(me.image)
      ? me.image
      : `${process.env.REACT_APP_SERVER}${me.image}`;
  }

  return (
    <div className="TopProfileDetail">
      <div className="Left">
        {me.image ? (
          <img src={image} alt={me.firstName} />
        ) : (
          <img src={Image} alt={me.firstName} />
        )}
        <div className="Detail">
          <h4>{me.firstName + " " + me.lastName}</h4>
          <ButtonBlueLine
            title="EDIT PROFILE"
            onClick={handleEditProfileModal}
          />
          {/* <Link to={`/sponsor-public/${me.id}`}>View Public Profile</Link> */}
        </div>
      </div>
      <div className="Right">
        <h4>Hi {me.firstName} {" "} {me.lastName},</h4>
        {sponsored === 0 ? (
          <p className="details-msg">
            Thank you for becoming an <strong>Educate Lanka Sponsor</strong>.
            Let’s start{" "}
            <strong onClick={() => navigate("/student-list")} className="hover-bold">
              sponsoring a student
            </strong>{" "}
            today.
          </p>
        ) : (
          <p className="details-msg">
            You have sponsored <strong>{sponsored == 'NaN' ? '...' : sponsored} students</strong> since{" "}
            <strong>{moment(me.joinDate).format("YYYY")}</strong>.{" "}
            <span className="colored-text">Thank you!</span>
          </p>
        )}
        {sponseButton}
      </div>
      <div className="ml-5 p-2">
        <ButtonBlueLine
          title="Sponsorship(s) summary"
          onClick={handleMultiStudentModal}
        />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <EditProfileModal
          token={props.auth.token}
          editUser={props.editUser}
          me={me}
          show={editModal}
          size="xl"
          onHide={handleEditProfileModal}
        />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <MultiStudenModal
          token={props.auth.token}
          editUser={props.editUser}
          me={me}
          show={multiStudentModal}
          size="xl"
          onHide={handleMultiStudentModal}
        />
      </Suspense>
      {/* <QuickPay show={quickPay} size="md" onHide={handleQuickPayModal} /> */}
    </div>
  );
};

export default TopProfileDetail;
